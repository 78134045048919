export const CALCULATOR_CAR_INITIAL_FORM_DATA = () => {
  return {
    start: {
      client: {
        firstName: "",
        lastName: "",
        withoutPesel: false,
        pesel: "",
        phoneNumber: "",
        phoneNumberPrefix: "+48",
        withoutEmail: false,
        email: "",
        companyName: "",
        nip: "",
        regon: "",
      },
    },
  };
};

export const CALCULATOR_CAR_INITIAL_FORM_STATE = () => {
  return {
    formState: null,
    start: {
      formState: null,
      client: {
        formState: null,
        isLoaded: null,

        currentStep: "searchClient",
      },
    },
  };
};
