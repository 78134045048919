<template>
  <div class="step-nav-wrapper">
    <div class="step-nav">
      <div
        class="nav-step"
        :class="isCurrentStep(1) && 'active-step'"
        @click="handleChangeStep(1)"
      >
        <div class="step-name">Start</div>
      </div>
      <div
        class="nav-step"
        :class="isCurrentStep(2) && 'active-step'"
        @click="handleChangeStep(2)"
      >
        <div class="step-name">IDD</div>
      </div>
      <div
        class="nav-step"
        :class="isCurrentStep(3) && 'active-step'"
        @click="handleChangeStep(3)"
      >
        <div class="step-name">Zakres</div>
      </div>
      <div
        class="nav-step"
        :class="isCurrentStep(4) && 'active-step'"
        @click="handleChangeStep(4)"
      >
        <div class="step-name">Podmiot</div>
      </div>
      <div
        class="nav-step"
        :class="isCurrentStep(5) && 'active-step'"
        @click="handleChangeStep(5)"
      >
        <div class="step-name">Pojazd</div>
      </div>
      <div
        class="nav-step"
        :class="isCurrentStep(6) && 'active-step'"
        @click="handleChangeStep(6)"
      >
        <div class="step-name">Zniżki</div>
      </div>
      <div
        class="nav-step"
        :class="isCurrentStep(7) && 'active-step'"
        @click="handleChangeStep(7)"
      >
        <div class="step-name">Oferty</div>
      </div>
      <div
        class="nav-step"
        :class="isCurrentStep(8) && 'active-step'"
        @click="handleChangeStep(8)"
      >
        <div class="step-name">Polisa</div>
      </div>
    </div>
    <div class="calculation-info">
      Kalkulacja 158264<br />
      Andrzej Duda
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  props: {
    state: {
      type: Object,
      default: null,
    },
    form: {
      type: Object,
      default: null,
    },
    handleChangeStep: {
      type: Function,
      default: null,
    },
    isCurrentStep: {
      type: Function,
      default: null,
    },
  },
};
</script>
