<template>
  <div class="row">
    <div class="col-lg-3">
      <tabs>
        <tab
          id="searchClient"
          :is-active="state.client.activeTab === 'searchClient'"
          title="Wyszukaj klienta"
          :change="handleSetCurrentStep"
        >
          <fieldset :disabled="isClientChecking">
            <div
              class="form-group"
              :class="isFieldInvalid('firstName', 'client') && 'invalid'"
            >
              <label for="firstName">Imię</label>
              <input
                id="firstName"
                v-model="form.client.firstName"
                type="text"
                class="form-control"
                name="firstName"
              />
              <div
                v-if="isFieldInvalid('firstName', 'client')"
                class="invalid-feedback"
              >
                Uzupełnij to pole
              </div>
            </div>
            <div
              class="form-group"
              :class="isFieldInvalid('lastName', 'client') && 'invalid'"
            >
              <label for="lastName">Nazwisko</label>
              <input
                id="lastName"
                v-model="form.client.lastName"
                type="text"
                class="form-control"
                name="lastName"
              />
              <div
                v-if="isFieldInvalid('lastName', 'client')"
                class="invalid-feedback"
              >
                Uzupełnij to pole
              </div>
            </div>
            <div class="d-flex align-items-center erasable-input-group">
              <div
                class="form-group"
                :class="
                  (isFieldInvalid('pesel', 'client') || isPeselInvalid()) &&
                  'invalid'
                "
              >
                <label for="pesel">PESEL</label>
                <input
                  id="pesel"
                  v-model="form.client.pesel"
                  v-mask="'###########'"
                  type="text"
                  class="form-control"
                  name="pesel"
                  @change="handleChangePesel"
                  @input="
                    () => {
                      if (state.client.isLoaded === true) {
                        state.client.isLoaded = null;
                      }
                    }
                  "
                />
                <div
                  v-if="isFieldInvalid('pesel', 'client') || isPeselInvalid()"
                  class="invalid-feedback"
                >
                  <template v-if="isFieldInvalid('pesel', 'client')">
                    Uzupełnij to pole
                  </template>
                  <template v-else-if="isPeselInvalid()">
                    Nieprawidłowy PESEL
                  </template>
                </div>
              </div>
              <div v-if="form.client.pesel !== ''" class="erase-icon">
                <img
                  alt=""
                  class="cursor-pointer"
                  src="/media/components/form/form-erase.svg"
                  @click="handleErase('pesel', 'client')"
                />
              </div>
              <div class="check-buttons">
                <div class="check muted">
                  <input
                    id="withoutPesel"
                    v-model="form.client.withoutPesel"
                    name="withoutPesel"
                    type="checkbox"
                    @change="handleChangeWithoutPesel"
                  />
                  <label for="withoutPesel" class="text-muted"
                    >Cudzoziemiec</label
                  >
                </div>
              </div>
            </div>
            <div
              class="form-group"
              :class="
                (isFieldInvalid('phoneNumber', 'client') ||
                  isPhoneNumberInvalid()) &&
                'invalid'
              "
            >
              <label for="pesel">Numer telefonu</label>
              <div class="input-group">
                <button
                  v-click-outside="hidePhonePrefix"
                  class="btn btn-outline-gray-300 text-gray-900 px-2"
                  type="button"
                  @click.stop="openPhonePrefix = true"
                >
                  {{ form.client.phoneNumberPrefix }}
                  <img
                    alt=""
                    src="/media/components/form/form-angle-down.svg"
                    style="margin-top: -2px; margin-left: 10px"
                  />
                </button>
                <ul
                  v-show="openPhonePrefix"
                  class="dropdown-menu show"
                  style="top: 35px"
                >
                  <li>
                    <div
                      class="dropdown-item cursor-pointer"
                      @click="
                        form.client.phoneNumberPrefix = '+48';
                        openPhonePrefix = false;
                      "
                    >
                      +48
                    </div>
                  </li>
                  <li>
                    <div
                      class="dropdown-item cursor-pointer"
                      @click="
                        form.client.phoneNumberPrefix = '+49';
                        openPhonePrefix = false;
                      "
                    >
                      +49
                    </div>
                  </li>
                  <li>
                    <div
                      class="dropdown-item cursor-pointer"
                      @click="
                        form.client.phoneNumberPrefix = '+50';
                        openPhonePrefix = false;
                      "
                    >
                      +50
                    </div>
                  </li>
                </ul>
                <input
                  id="phoneNumber"
                  v-model="form.phoneNumber"
                  v-mask="'###-###-###'"
                  class="form-control"
                  name="phoneNumber"
                  type="text"
                />
              </div>

              <div
                v-if="
                  isFieldInvalid('phoneNumber', 'client') ||
                  isPhoneNumberInvalid()
                "
                class="invalid-feedback"
              >
                <template v-if="isFieldInvalid('phoneNumber', 'client')">
                  Uzupełnij to pole
                </template>
                <template v-else-if="isPhoneNumberInvalid()">
                  Nieprawidłowy numer telefonu
                </template>
              </div>
            </div>
            <div
              class="form-group"
              :class="isFieldInvalid('companyName', 'client') && 'invalid'"
            >
              <label for="companyName">Nazwa firmy</label>
              <input
                id="companyName"
                v-model="form.client.companyName"
                type="text"
                class="form-control"
                name="companyName"
              />
              <div
                v-if="isFieldInvalid('companyName', 'client')"
                class="invalid-feedback"
              >
                Uzupełnij to pole
              </div>
            </div>
            <div class="d-flex align-items-center erasable-input-group">
              <div
                class="form-group"
                :class="isFieldInvalid('regon', 'client') && 'invalid'"
              >
                <label for="regon">REGON</label>
                <input
                  id="regon"
                  v-model="form.client.regon"
                  v-mask="'#########'"
                  type="text"
                  class="form-control"
                  name="regon"
                  @input="
                    () => {
                      if (state.isLoaded === true) {
                        state.isLoaded = null;
                      }
                    }
                  "
                />
                <div
                  v-if="isFieldInvalid('regon', 'client')"
                  class="invalid-feedback"
                >
                  Uzupełnij to pole
                </div>
              </div>
              <div v-if="form.client.regon !== ''" class="erase-icon">
                <img
                  alt=""
                  class="cursor-pointer"
                  src="/media/components/form/form-erase.svg"
                  @click="handleErase('regon', 'client')"
                />
              </div>
            </div>
            <SearchGroup
              :is-loaded="state.client.isLoaded"
              :is-loading="isClientChecking"
              error-icon="form-declined-fill"
              error-text="Uzupełnij dane klienta"
              success-icon="form-approved"
              success-text="Klient jest w systemie"
              @handleSearch="handleSearchClient"
            />
          </fieldset>
        </tab>
      </tabs>
    </div>
    <div class="col-lg-9">Tabelka</div>
  </div>
</template>

<script>
import Tabs from "@/view/content/components/tabs/Tabs";
import Tab from "@/view/content/components/tabs/Tab";
import SearchGroup from "@/view/content/components/search-group/SearchGroup";

export default {
  name: "Start",
  components: { SearchGroup, Tabs, Tab },
  props: {
    v: {
      type: Object,
      default: null,
    },
    form: {
      type: Object,
      default: null,
    },
    state: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      openPhonePrefix: false,
      isClientChecking: false,
    };
  },
  watch: {
    v: {
      handler() {
        this.$nextTick(() => {
          for (let item in this.v) {
            if (this.v[item].hasOwnProperty("$reset")) {
              if (
                (this.v[item].$model !== "" && this.v[item].$model !== null) ||
                (this.v[item].$dirty &&
                  !this.v[item].$error &&
                  this.v[item].$model === "" &&
                  this.v[item].$model === null)
              ) {
                this.v[item].$reset();
              }
            }
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    resetClient() {
      this.form.client.pesel = "";
      this.form.client.firstName = "";
      this.form.client.lastName = "";
      this.form.client.phoneNumber = "";
      this.form.client.email = "";

      this.state.client.isLoaded = null;
    },
    hidePhonePrefix() {
      this.openPhonePrefix = false;
    },
    handleSetCurrentStep(step) {
      this.state.currentStep = step;
    },
    isFieldInvalid(field, section = "") {
      if (section) {
        return this.v[section][field].$dirty && this.v[section][field].$invalid;
      } else {
        return this.v[field].$dirty && this.v[field].$invalid;
      }
    },
    isPeselInvalid() {
      return !this.v.client.pesel.isValid;
    },

    isPhoneNumberInvalid() {
      return (
        !this.v.client.phoneNumber.minLength ||
        !this.v.client.phoneNumber.maxLength
      );
    },
    handleErase(field, section = "") {
      if (section) {
        this.form[section][field] = "";
      } else {
        this.form[field] = "";
      }
    },
    handleChangePesel() {
      if (this.form.pesel !== "") {
        this.handleDecodePesel(this.form.pesel);
      }
    },
    handleSearchClient() {
      this.isClientChecking = true;
      setTimeout(() => {
        if (this.form.withoutPesel === false) {
          if (this.form.pesel !== "") {
            this.handleDecodePesel(this.form.pesel);
          }
          if (this.form.pesel === "11111111111") {
            this.form.firstName = "Jan";
            this.form.lastName = "Kowalski";
            this.form.phoneNumber = "123 456 789";
            this.form.email = "jan.kowalski@domena.pl";
            this.state.isLoaded = true;
          } else {
            this.state.isLoaded = false;
          }
          this.isClientChecking = false;
        } else {
          if (
            this.form.firstName === "Jan" &&
            this.form.lastName === "Kowalski"
          ) {
            this.form.birthDate = "12-04-1988";
            this.form.gender = "male";

            this.form.phoneNumber = "123 456 789";
            this.form.email = "jan.kowalski@domena.pl";
            this.state.isLoaded = true;
          } else {
            this.state.isLoaded = false;
          }
          this.isClientChecking = false;
        }
      }, 1000);
    },
    handleChangeWithoutPesel() {
      if (this.form.client.withoutPesel) {
        this.resetClient();
      }
    },
  },
};
</script>
