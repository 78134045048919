<template>
  <div>
    <div class="steps-wrapper custom-steps-2">
      <Nav
        :state="state"
        :form="form"
        :is-current-step="isCurrentStep"
        :handle-change-step="handleChangeStep"
      />
      <div class="step-content">
        <form novalidate @submit.stop.prevent="handleSubmit">
          <transition name="fade">
            <div v-show="isCurrentStep(1)" id="step1" class="step-wrapper">
              <Start
                :form="form.start"
                :state="state.start"
                :v="$v.form.start"
              />
            </div>
          </transition>
        </form>
      </div>
      <div class="step-actions">
        <div class="row align-items-center">
          <div class="col-4">
            <img
              src="/media/components/steps/steps-previous-arrow.svg"
              alt=""
              class="cursor-pointer"
              @click="handlePreviousStep"
            />
          </div>
          <div class="col-4 text-center">
            <button
              v-show="!isLastStep() || state.idd.activeTab === 'issue'"
              type="button"
              class="btn btn-primary btn-lg btn-normal"
              @click="handleNextStep"
            >
              Dalej
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "./Nav";
import {
  requiredIf,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import peselValidator from "@/core/utils/validators/pesel";
import nipValidator from "@/core/utils/validators/nip";

import {
  CALCULATOR_CAR_INITIAL_FORM_DATA,
  CALCULATOR_CAR_INITIAL_FORM_STATE,
} from "@/core/helpers/CalculatorCarFormState";
import Start from "@/view/content/calculator/car/steps/start/Start";

export default {
  name: "CalculatorCarView",
  components: {
    Start,
    Nav,
  },
  data() {
    return {
      currentStep: 1,
      lastStep: 8,
      isFinishedModalVisible: false,
      state: { ...CALCULATOR_CAR_INITIAL_FORM_STATE() },
      form: { ...CALCULATOR_CAR_INITIAL_FORM_DATA() },
    };
  },
  validations() {
    return {
      form: {
        start: {
          client: {
            regon: {
              required: requiredIf(() => {
                return this.isClientFieldRequired("regon");
              }),
            },
            companyName: {
              required: requiredIf(function () {
                return this.isClientFieldRequired("companyName");
              }),
            },
            nip: {
              required: requiredIf(function () {
                return this.isClientFieldRequired("nip");
              }),
              isValid: this.form.start.client.nip !== "" && nipValidator,
            },
            pesel: {
              required: requiredIf(function () {
                return this.isClientFieldRequired("pesel");
              }),
              isValid: this.form.start.client.pesel !== "" && peselValidator,
            },
            firstName: {
              required: requiredIf(function () {
                return this.isClientFieldRequired("firstName");
              }),
            },
            lastName: {
              required: requiredIf(function () {
                return this.isClientFieldRequired("lastName");
              }),
            },
            phoneNumber: {
              minLength:
                this.form.start.client.phoneNumber !== "" && minLength(11),
              maxLength:
                this.form.start.client.phoneNumber !== "" && maxLength(11),
            },
            email: {
              required: requiredIf(function () {
                return this.isClientFieldRequired("email");
              }),
              isValid: this.form.start.client.email !== "" && email,
            },
          },
        },
      },
    };
  },
  methods: {
    isCurrentStep(step) {
      return this.currentStep === step;
    },
    isLastStep() {
      return this.currentStep === this.lastStep;
    },
    getNextStep() {
      let nextStep = this.currentStep;
      nextStep += 1;
      return nextStep;
    },
    getPreviousStep() {
      let previousStep = this.currentStep;
      if (this.currentStep > 1) {
        previousStep -= 1;
      }
      return previousStep;
    },
    getStepNameByNumber(stepNumber) {
      switch (stepNumber) {
        case 1:
          return "start";
        case 2:
          return "idd";
        case 3:
          return "range";
        case 4:
          return "subject";
        case 5:
          return "car";
        case 6:
          return "discounts";
        case 7:
          return "offer";
        case 8:
          return "policy";
        default:
          return "";
      }
    },
    handleNextStep() {
      if (this.currentStep <= this.lastStep) {
        const stepName = this.getStepNameByNumber(this.currentStep);
        this.validateFormSection(stepName);
        if (this.state[stepName].formState) {
          this.currentStep += 1;
        }
      }
    },
    handlePreviousStep() {
      if (this.currentStep > 1) {
        this.currentStep -= 1;
      }
    },
    handleChangeStep(step) {
      if (step > this.getPreviousStep()) {
        for (let i = this.currentStep; i < step; i++) {
          const stepName = this.getStepNameByNumber(i);
          this.validateFormSection(stepName);
          if (!this.state[stepName].formState) {
            return false;
          }
          this.currentStep += 1;
        }
      } else {
        this.currentStep = step;
      }
    },
    isClientFieldRequired(field) {
      switch (field) {
        case "companyName":
        case "nip":
        case "regon":
          return false; // Company validation
        case "firstName":
        case "lastName":
          return false; // Private validation
        case "pesel":
          return this.form.start.client.withoutPesel === false; // Pesel validation
        case "email":
          return this.form.start.client.withoutEmail === false; // Email validation
        case "phoneNumber":
          return false; // Phone number validation
      }
    },
    resetForm() {
      this.state = CALCULATOR_CAR_INITIAL_FORM_STATE();
      this.form.start = CALCULATOR_CAR_INITIAL_FORM_DATA().start;
      this.form.start.client = CALCULATOR_CAR_INITIAL_FORM_DATA().start.client;

      this.currentStep = 1;

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    validateFormSection(section, subsection = null) {
      if (subsection === null) {
        this.$v.form[section].$touch();
        this.state[section].formState = !this.$v.form[section].$invalid;
      } else {
        this.$v.form[section][subsection].$touch();
        this.state[section][subsection].formState =
          !this.$v.form[section][subsection].$invalid;
      }
    },
    validateForm() {
      this.$v.$touch();
      this.state.formState = !this.$v.$invalid;
    },
    handleSubmit() {
      this.validateForm();
      if (this.state.formState) {
        // Handle after submit
      }
    },
  },
};
</script>
