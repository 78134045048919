<template>
  <div class="container px-0 h-100">
    <CalculatorCarView />
  </div>
</template>

<script>
import CalculatorCarView from "../../../content/calculator/car/View";
export default {
  name: "CalculatorCarPageView",
  components: { CalculatorCarView },
};
</script>
